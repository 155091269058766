import React from "react";
import { Helmet } from "react-helmet";
import logo from '../../assets/logo.png'
import pipeimg from '../../assets/pipe product.png';
// import eye from '../../assets/view.png';
import Boxer4D from '../../assets/Boxer4D.png';
import icon1 from '../../assets/tmt-product icon-1.png';
import icon2 from '../../assets/tmt-product icon-2.png';
import tmtbar from '../../assets/tmtbar.png'
import "../../css/TMTProduct.css"

const TMTProduct = () => {
    return (
        <>
            <Helmet>
                <title>Rudra TMT Bars | 4D Boxer Bars</title>
            </Helmet>
            <div className="tmt-product">
                <section className="home-tmt-product pb-5">
                    <div className="container">
                        <div className="row align-items-center justify-content-center mb-5">
                            <div className="col-12 col-sm-8 col-md-6 col-lg-5">
                                <h1 className="display-4 text-yellow">
                                    Advantage Of 
                                </h1>
                                <img src={logo} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row gy-3">
                            <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                                <div className="card mb-3 h-100">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-3">
                                            <div className="card-body bullet-container pb-0">
                                                <div className="bullet"></div>
                                            </div>
                                        </div>
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-9">
                                            <div className="card-body ps-0 pb-0">
                                                <h5 className="card-title fw-bold mb-0">Integrated Plant</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-0">
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-3"></div>
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-9">
                                            <div className="card-body ps-0">
                                                <p className="card-text"><small className="text-muted fw-semibold">Our State of the Art Integrated Manufacturing facility at Bhavnagar, contributes in manufacturing high strength TMT Bars.</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                                <div className="card mb-3 h-100">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-3">
                                            <div className="card-body bullet-container pb-0">
                                                <div className="bullet"></div>
                                            </div>
                                        </div>
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-9">
                                            <div className="card-body ps-0 pb-0">
                                                <h5 className="card-title fw-bold mb-0">Corrosion Resistant</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-0">
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-3"></div>
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-9">
                                            <div className="card-body ps-0">
                                                <p className="card-text"><small className="text-muted fw-semibold">We have ensured the eradication of Corrosion on TMT bars which ultimately impacts increase in the strength of construction and building lifespan.</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                                <div className="card mb-3 h-100">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-3">
                                            <div className="card-body bullet-container pb-0">
                                                <div className="bullet"></div>
                                            </div>
                                        </div>
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-9">
                                            <div className="card-body ps-0 pb-0">
                                                <h5 className="card-title fw-bold mb-0">International Standards</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-0">
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-3"></div>
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-9">
                                            <div className="card-body ps-0">
                                                <p className="card-text"><small className="text-muted fw-semibold">Rudra Products are qualified with Indian, European, Russian and American Standards.</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                                <div className="card mb-3 h-100">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-3">
                                            <div className="card-body bullet-container pb-0">
                                                <div className="bullet"></div>
                                            </div>
                                        </div>
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-9">
                                            <div className="card-body ps-0 pb-0">
                                                <h5 className="card-title fw-bold mb-0">Product Variants Availability</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-0">
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-3"></div>
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-9">
                                            <div className="card-body ps-0">
                                                <p className="card-text"><small className="text-muted fw-semibold">Our product range caters to numerous needs of industry by varies carbon content and size ranges from 8mm to 40mm.</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="section-2">
                    <div className="row gy-4 align-items-center">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="card">
                                <h3 className="c-blue heading">When You <br /> Invest In</h3>
                                <h1 className="c-blue heading fw-bold">RUDRA TMX BARS,</h1>
                                <h3 className="text-yellow heading fw-semibold">You <b>Invested In</b></h3>
                                <h1 className="text-yellow heading fw-bold">STEEL FOR LIFE.</h1>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="timeline-heading">
                                <h4 className="text-yellow sub-heading">Steel So True, <br /> It Speaks For Itself</h4>
                            </div>
                            <ul>
                                <li>
                                    <div class="main-container">

                                        <div className="not-li">
                                            <p className="normal-text text-wrapper">Rudra ensures this by focusing on 3 important parameters during the manufacturing process.</p>
                                        </div>
                                        <div class="text-wrapper">
                                            <div class="data">
                                                <h6 className="normal-text"><b>The Raw Material Billets</b></h6>
                                                <p className="normal-text">Most CTD/TMT manufacturers use re-rollable scrap or Ms Ingots, which do not offer control over the property of the steel manufactured.</p>
                                            </div>
                                        </div>
                                        <div class="text-wrapper">
                                            <div class="data">
                                                <h6 className="normal-text"><b>The Unique Thermex Quenching Process</b></h6>
                                                <p className="normal-text">The outer temperature of the bars is reduces from 1000 C to 450 C in half a second by intensive controlled cooling.</p>
                                            </div>
                                        </div>
                                        <div class="text-wrapper">
                                            <div class="data">
                                                <h6 className="normal-text"><b>Stringent Quality Testing</b></h6>
                                                <p className="normal-text">Stringent quality tests are conducted at every stage and the desired chemistry maintained right through the process.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="banner-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                                <img src={Boxer4D} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-container py-5">
                    <div className="container">
                        <div className="row py-5">
                            <h1 className="display-4 fw-bold">
                                Introducing India's <br/>
                                    Most <span className="yellow-text">Advanced</span> <br/>
                                        TMT Bars
                            </h1>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row gy-3">
                            <div className="col-12 col-lg-6">
                                <h5 className="fw-bold">
                                    Additional Longitudinal Ribs For <br />
                                    Extra Strength And Bonding
                                </h5>
                            </div>
                            <div className="col-12 d-block d-sm-none rod">
                                    {/* <img src={tmtbar} alt="" className="img-fluid" /> */}
                            </div>
                            <div className="col-12 col-lg-6 specs">
                                <div className="row">
                                    <div className="col-4">
                                        <h5 className="yellow-text">
                                            Strength
                                        </h5>
                                    </div>
                                    <div className="col-8">
                                        <h5 className="fw-300">
                                            Superior <br />
                                            Tensile
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6"></div>
                            <div className="col-12 col-lg-6">
                                <div className="row">
                                    <div className="col-4">
                                        <h5 className="yellow-text">
                                            IDEA
                                        </h5>
                                    </div>
                                    <div className="col-8">
                                        <h5 className="fw-300">
                                            Superior Resistance to <br />
                                            sustain stress without failure
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6"></div>
                            <div className="col-12 col-lg-6">
                                <div className="row">
                                    <div className="col-4">
                                        <h5 className="yellow-text">
                                            POWER
                                        </h5>
                                    </div>
                                    <div className="col-8">
                                        <h5 className="fw-300">
                                            Superior <br />
                                            Elongation
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="d-flex flex-column-reverse d-md-flex">
                                
                            </div> */}
                            <div className="col-12 col-lg-6 licence m-0">
                                <img src={icon1} alt="" className="img-fluid licence-img me-5" />
                                <img src={icon2} alt="" className="img-fluid licence-img" />
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="row">
                                    <div className="col-4">
                                    </div>
                                    <div className="col-8">
                                        <h5 className="fw-300">
                                            Superior <br />
                                            Appeal
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 licence-mobile mt-4">
                                <img src={icon1} alt="" className="img-fluid licence-img me-5" />
                                <img src={icon2} alt="" className="img-fluid licence-img" />
                            </div>


                            {/* <div className="col-6">
                                <div className="row h-100">
                                    <div className="col-12">
                                        <h5 className="fw-bold">
                                            Additional Longitudinal Ribs For <br />
                                            Extra Strength And Bonding
                                        </h5>
                                    </div>
                                    <div className="col-12 d-flex flex-column justify-content-end">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-12 licence">
                                                <img src={icon1} alt="" className="img-fluid licence-img me-5" />
                                                <img src={icon2} alt="" className="img-fluid licence-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row gy-3">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <h5 className="yellow-text">
                                                    Strength
                                                </h5>
                                            </div>
                                            <div className="col-8">
                                                <h5 className="fw-300">
                                                    Superior <br />
                                                    Tensile
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <h5 className="yellow-text">
                                                    IDEA
                                                </h5>
                                            </div>
                                            <div className="col-8">
                                                <h5 className="fw-300">
                                                    Superior Resistance to <br />
                                                    sustain stress without failure
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <h5 className="yellow-text">
                                                    POWER
                                                </h5>
                                            </div>
                                            <div className="col-8">
                                                <h5 className="fw-300">
                                                    Superior <br />
                                                    Elongation
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                            </div>
                                            <div className="col-8">
                                                <h5 className="fw-300 m-0">
                                                    Superior <br />
                                                    Elongation
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="row">
                            <div className="col-12 py-5 footer-text">
                                <h1 className="yellow-text">
                                    THE FUTURE OF THE STEEL
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-3">
                    <h1 className="text-yellow fw-7">Product Technical <br /> Specification</h1>
                    <div className="row">
                        <div className="col-5 col-md-5 col-lg-5 col-xl-5">
                            {/* <h1 className="text-yellow"><a href="http://rudratmx.com/tmtbar_specification.pdf" target="_blank" style={{textDecoration: 'none', color: '#ED9E41'}}>View</a></h1> */}

                            <div className="tmt-bar">
                                <h2 className="heading text-yellow fw-7">TMT BAR</h2>
                                <a href="http://rudratmx.com/tmtbar_specification.pdf" target="_blank" className="normal-text text-white">View</a>
                                {/* <div className="d-flex ">
                                    <img src={eye} alt="" />
                                    <h2 className="text-yellow">PDF</h2>
                                </div> */}
                            </div>

                            <div className="boxer-4d">
                                <h2 className="heading text-yellow fw-7">Boxer 4D BAR</h2>
                                <a href="http://rudratmx.com/tmtbar_specification.pdf" target="_blank" className="normal-text text-white">View</a>
                                {/* <img src={Boxer4D} alt="" /> */}
                                {/* <div className="d-flex ">
                                    <img src={eye} alt="" />
                                    <h2 className="text-yellow" style={{color: '#ED9E41'}}>PDF</h2>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-7 col-md-7 col-lg-7 col-xl-7">
                            <img src={pipeimg} alt="" />
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default TMTProduct;