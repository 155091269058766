import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import ScrollAnimation from "react-animate-on-scroll";
import InquiryService from "../../api-config/Inquiry";
import SliderService from "../../api-config/HomePageSlider";
import ProductRange from "./Slider";
// import ProductRange_old from "./Slider_old";
import { inquiryFormSchema } from "../../validations/InquiryForm";
// import "../../css/style.css";
import "../../css/home.css";
import "../../css/common.css";
// import rod from '../../assets/mobile tmt.png';
import rod from "../../assets/rudra_tmt_advantage.png";
import rod_mobile from "../../assets/TMT_mobile.png";
import img2 from "../../assets/MicrosoftTeams-image (2).png";
import img3 from "../../assets/MicrosoftTeams-image (3).png";
import img1 from "../../assets/MicrosoftTeams-image.png";
import video from "../../assets/istockphoto-1313906909-640_adpp_is.mp4";

const Home = () => {
  const [volume, setVolume] = useState(2);
  const [sliderData, setSliderData] = useState();
  const [img, setImage] = useState();

  useEffect(() => {
    SliderService.getData(volume)
      .then((res) => {
        console.log(res.data.data[0]);
        setImage(res.data.data[0]?.image);
        setSliderData(res.data.data[0]);
      })
      .catch((e) => console.log(e));
  }, [setVolume, volume]);

  const formik = useFormik({
    initialValues: {
      title: "",
      first_name: "",
      last_name: "xyz",
      primary_contact_number: "",
      message: "",
      status: "false",
      is_dealer: true,
    },
    validateOnChange: true,
    // enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      InquiryService.saveDealerInquiry(values)
        .then((res) => {
          if (res.data.success) {
            InquiryService.sendEmail(values).then((res) => {
              console.log(res);
            });
            // InquiryService.sendSMS(values).then((res) => {
            //   console.log(res);
            // });
            resetForm();
            toast.success(res.data.message, { autoClose: 1000 });
          } else {
            toast.error(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((e) => console.log(e));

      const requestData = {
        title: values?.title ?? "",
        first_name: values?.first_name ?? "",
        primary_contact_number: values?.primary_contact_number ?? "",
      };
      InquiryService.thankU(requestData)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, { autoClose: 1000 });
          } else {
            toast.error(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((e) => console.log(e));

      const data = {
        title: values?.title ?? "",
        first_name: values?.first_name ?? "",
        primary_contact_number: values?.primary_contact_number ?? "",
        message: values?.message ?? "",
        status: values.status,
        is_dealer: values.is_dealer,
        inquiry_type: "Dealer Inquiry",
      };

      InquiryService.sendInquirySMS(data)
        .then((res) => {
          console.log(res);
        })
        .catch((e) => console.log(e));
    },
    validationSchema: inquiryFormSchema,
    validateOnBlur: true,
  });

  const { handleChange, handleSubmit } = formik;

  return (
    <>
      <Helmet>
        <title>
          TMT Steel Bars Manufacturer in Ahmedabad Gujarat India | Rudra TMX
        </title>
      </Helmet>

      {/* home-section-start */}
      <section className="home-screen">
        <div className="home-video">
          <video autoPlay muted loop id="myVideo">
            <source
              src="https://storage.googleapis.com/rudratmx-firebase.appspot.com/1681901757844_MicrosoftTeams-video.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        {/* <div className="container-fluid h-100">
          <div className="home-screen-content">
            <div className="home-inner-content text-white d-flex flex-column justify-content-end me-lg-5 me-md-3 me-2">
              <h1 className="display-4 fw-semibold text-end mb-3">
                Integrated
                <br />
                Production Plant
              </h1>
              <h5 className="text-end text-break">
                Our integrated plant technology
              </h5>
              <h5 className="text-end text-break">
                ensure each product manufactured
              </h5>
              <h5 className="text-end text-break">
                is of same strength and quality.
              </h5>
            </div>
          </div>
        </div> */}
      </section>
      {/* home-section-end */}

      {/* advantage-section-start */}
      <section className="advantage">
        <div className="container-fluid">
          <h1 className="display-6 fw-semibold mt-5 ps-3 ps-lg-4 ps-xl-5 pb-5 mb-md-0">
            The Rudra Advantage
          </h1>
          {/* <div className="row py-5 pb-md-3 py-lg-0">
            <div className="container">
              <div className="col-12">
                <h1 className="display-6 fw-semibold rudra-advantage-title ps-3 ps-lg-4 ps-xl-5">
                  The Rudra Advantage
                </h1>
              </div>
            </div>
          </div> */}
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row advantage-main-row justify-content-center">
              <div className="col-lg-12 col-md-12 col-5">
                <div className="row upper-row justify-content-center">
                  <div className="col-lg-4 col-md-4 col-10 my-5 my-md-0 my-lg-0 advantage-points fs-5">
                    Made from 100% Billet
                    <div class="outer-circle">
                      <div class="inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-10 my-5 my-md-0 my-lg-0 advantage-points fs-5">
                    Corrosion Resistance
                    <div class="outer-circle">
                      <div class="inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-10 my-5 my-md-0 my-lg-0 advantage-points fs-5">
                    Consistent Quality
                    <div class="outer-circle">
                      <div class="inner-circle"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-1 advantage-mobile-rod p-0 py-lg-3 py-md-3 mx-2 mx-md-0 mx-lg-0">
                <img src={rod} alt="" className="rod_image img-fluid" />
              </div>
              <div className="col-lg-12 col-md-12 col-5">
                <div className="row lower-row justify-content-center">
                  <div className="col-lg-4 col-md-4 col-10 my-5 my-md-0 my-lg-0 advantage-points fs-5">
                    <div class="outer-circle">
                      <div class="inner-circle"></div>
                    </div>
                    Sulphur & Phosphorus below <br className="d-none d-" />
                    0.075% in all grades
                  </div>
                  <div className="col-lg-4 col-md-4 col-10 my-5 my-md-0 my-lg-0 advantage-points fs-5">
                    <div class="outer-circle">
                      <div class="inner-circle"></div>
                    </div>
                    <p className="m-0">THERMEX Certifies</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* advantage-section-start */}

      {/* <ProductRange /> */}

      <section className="rudra-member py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-8 d-flex flex-column justify-content-center">
              <div className="row my-4">
                <div className="col-12">
                  <h1 className="display-4 text-white fw-semibold">
                    Become A <br /> Rudra Member
                  </h1>
                </div>
                <div className="col-12">
                  <p className="text-white">
                    Rudra Dealership Arrives With <br /> A Bouque Of Exclusive
                    Benefits.
                  </p>
                </div>
              </div>
              <div className="row rudra-desktop mt-4 mt-md-5">
                <div className="col-12">
                  <div className="row">
                    <div className="col-4 member-item">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="img-container">
                          <img src={img1} alt="" className="img-fluid" />
                        </div>
                        <h6 className="text-center">
                          Exclusive <br /> Localised <br /> Dealership
                        </h6>
                      </div>
                    </div>
                    <div className="col-4 member-item">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="img-container">
                          <img src={img2} alt="" className="img-fluid" />
                        </div>
                        <h6 className="text-center">
                          Premium <br /> Bikes
                        </h6>
                      </div>
                    </div>
                    <div className="col-4 member-item">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="img-container">
                          <img src={img3} alt="" className="img-fluid" />
                        </div>
                        <h6 className="text-center">
                          Regular <br /> Gifting
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <p>Fill The Form To Enquire More</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4 my-5">
              <ScrollAnimation animateIn="zoomIn" duration={1} delay={2}>
                <div className="container">
                  <form class="row g-3 p-3" onSubmit={handleSubmit}>
                    <div class="col-12">
                      <label htmlFor="title" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        aria-describedby="title"
                        value={formik?.values.title}
                        onChange={handleChange}
                      />
                      {/* <p className="text-danger">{formik?.errors?.title}</p> */}
                    </div>
                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                      <label htmlFor="first_name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        value={formik?.values.first_name}
                        onChange={handleChange}
                      />
                      {/* <p className="text-danger">{formik?.errors?.first_name}</p> */}
                    </div>
                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                      <label
                        htmlFor="primary_contact_number"
                        className="form-label"
                      >
                        Contact No.
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="primary_contact_number"
                        name="primary_contact_number"
                        value={formik?.values.primary_contact_number}
                        onChange={handleChange}
                      />
                      {/* <p className="text-danger">{formik?.errors?.primary_contact_number}</p> */}
                    </div>
                    <div class="col-12">
                      <label for="inputAddress2" class="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="2"
                        value={formik?.values.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div class="d-grid mb-3">
                      <button
                        type="submit"
                        class="btn btn-primary rudra-member-submit-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="row rudra-mobile mt-3">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-12 mb-4">
                  <p>Fill The Form To Enquire More</p>
                </div>
                <div className="col-4 member-item">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="img-container">
                      <img src={img1} alt="" className="img-fluid" />
                    </div>
                    <h6 className="text-center">
                      Exclusive <br /> Localised <br /> Dealership
                    </h6>
                  </div>
                </div>
                <div className="col-4 member-item">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="img-container">
                      <img src={img2} alt="" className="img-fluid" />
                    </div>
                    <h6 className="text-center">
                      Premium <br /> Bikes
                    </h6>
                  </div>
                </div>
                <div className="col-4 member-item">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="img-container">
                      <img src={img3} alt="" className="img-fluid" />
                    </div>
                    <h6 className="text-center">
                      Regular <br /> Gifting
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="rudra-member py-5">
        <div class="row main-row align-items-center">
          <div class="col-md-5 col-lg-6">
            <div class="row">
              <div class="col-md-12">
                <div className="upper-heading">
                  <h3 className="text-white heading">Become A</h3>
                  <h3 className="text-white heading">Rudra Member</h3>
                  <br />
                  <p className="text-white normal-text">Rudra Dealership Arrives With</p>
                  <p className="text-white normal-text">A Bouque Of Exclusive Benefits.</p>
                </div>
              </div>
              <div class="col-md-12 desktop">
                <div className="options">
                  <div className="item">
                    <div className="img-container"><img src={img1} alt="" /></div>
                    <p className="normal-text fw-7 text-align-center ">Exclusive <br /> Localised  Dealership</p>
                  </div>
                  <div className="item">
                    <div className="img-container"><img src={img2} alt="" /></div>
                    <p className="normal-text fw-7 text-align-center ">Premium <br /> Bikes</p>
                  </div>
                  <div className="item">
                    <div className="img-container"><img src={img3} alt="" /></div>
                    <p className="normal-text fw-7 text-align-center ">Regular <br /> Gifting</p>
                  </div>
                </div>
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={1}
                  delay={1}
                ><p className="c-blue normal-text">Fill The Form To Enquire More</p></ScrollAnimation>

              </div>
            </div>
          </div>
          <div className="col-md-7 col-lg-6">
            <ScrollAnimation
              animateIn="zoomIn"
              duration={1}
              delay={2}
            >
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="title">Company Name</label>
                  <input type="text" className="form-control" id="title" name="title" aria-describedby="title"
                    value={formik?.values.title}
                    onChange={handleChange} />
                  <p className="text-red">{formik?.errors?.title}</p>
                </div>
                <div className="d-flex">
                  <div className="form-group" style={{ marginRight: 5 + 'px' }}>
                    <label htmlFor="first_name">Name</label>
                    <input type="text" className="form-control" id="first_name" name="first_name"
                      value={formik?.values.first_name}
                      onChange={handleChange} />
                    <p className="text-red">{formik?.errors?.first_name}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="primary_contact_number">Contact No.</label>
                    <input type="text" className="form-control" id="primary_contact_number" name="primary_contact_number"
                      value={formik?.values.primary_contact_number}
                      onChange={handleChange} />
                    <p className="text-red">{formik?.errors?.primary_contact_number}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea className="form-control" id="message" rows="3"
                    value={formik?.values.message}
                    onChange={handleChange}></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </ScrollAnimation>

          </div>
          <div class="col-md-12 mobile">
            <ScrollAnimation
              animateIn="fadeIn"
              duration={1}
              delay={1}
            ><p className="c-blue">Fill The Form To Enquire More</p></ScrollAnimation>
            <div className="options">
              <div className="item">
                <div className="img-container"><img src={img1} alt="" /></div>
                <p>Exclusive</p>
                <p>Localised  Dealership</p>
              </div>
              <div className="item">
                <div className="img-container"><img src={img2} alt="" /></div>
                <p>Premium</p>
                <p>Bikes</p>
              </div>
              <div className="item">
                <div className="img-container"><img src={img3} alt="" /></div>
                <p>Regular</p>
                <p>Gifting</p>
              </div>
            </div>


          </div>
        </div>
      </div> */}
    </>
  );
};

export default Home;
