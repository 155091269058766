import React, { useEffect, useState } from "react";
import ToggleButton from "react-toggle-button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css/scrollbar";
import PuffLoader from "react-spinners/PuffLoader";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import InvestorService from "../../api-config/Investor";
import "../../css/investor.css";
import { Helmet } from "react-helmet";

const InvestorRelationship = () => {
  const [value, setValue] = useState(false);
  const [yearData, setYear] = useState();
  const [reportsData, setReports] = useState();
  const [yearId, setYearId] = useState(); // current active year id
  const [investorId, setInvestorId] = useState(); // current active investor id
  const [otherId, setOtherId] = useState(); // current active other id
  const [hideYearData, setHideYearData] = useState(false);
  const [data, setData] = useState();
  const [showObject, setObject] = useState(false);
  const [hideObject, setHideObject] = useState(true);
  const [keyObject, setKeyObject] = useState();
  const [otherReports, setOtherReports] = useState();
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    InvestorService.getAllYear()
      .then((res1) => {
        setYear(res1.data.data);
        setYearId(res1.data.data[0].id);

        InvestorService.getAllReports()
          .then((res2) => {
            setReports(res2.data.data);
            setInvestorId(res2.data.data[0].id);

            InvestorService.getOtherReports().then((res) => {
              setOtherReports(res.data.data);
            });

            InvestorService.getCategorizedData(
              res1.data.data[0].id,
              res2.data.data[0].id,
            ).then((res) => {
              setData(res.data.data[0]?.value);
              setTimeout(() => {
                setLoading(false);
              }, 800);
            });
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  }, [setYearId, setInvestorId]);

  const setYearValue = (id) => {
    setYearId(id);
    setHideYearData(false);
    getCategorizedData(id, investorId);
    setShowButton(false);
    if (value) {
      setOtherId(null);
    }
    setObject(false);
    setHideObject(true);
  };

  const setInvestorValue = (id) => {
    setInvestorId(id);
    setHideYearData(false);
    // value ? null: setOtherId(null);
    if (!value) {
      setOtherId(null);
    }
    getCategorizedData(yearId, id);
    setShowButton(false);
    setObject(false);
  };
  const setObj = (key) => {
    if (showObject) {
      setObject(false);
      setKeyObject();
      setShowButton(true);
    } else {
      setHideObject(false);
      setObject(true);
      setKeyObject(key);
      setShowButton(true);
    }
  };

  const setBack = () => {
    setHideObject(true);
    setObject(false);
    setShowButton(false);
  };

  const getCategorizedData = (yearId, investorId) => {
    InvestorService.getCategorizedData(yearId, investorId).then((res) => {
      console.log(res.data.data);
      setData(res.data.data[0]?.value);
    });
  };

  const getInvestorData = (id) => {
    InvestorService.getInvestorData(id).then((res) => {
      setData(res.data.data[0]?.value);
    });
  };

  const setOtherReportsdata = (id) => {
    console.log("other report");
    getInvestorData(id);
    setOtherId(id);
    setHideYearData(true);
    !value ? setInvestorId(null) : setYearId(null);
    // setYear();
    // setData(value);
  };

  return (
    <>
      <Helmet>
        <title>
          Investor Relationship | Rudra Global Infta Products Ltd Bhavanagar
        </title>
      </Helmet>
      {!loading ? (
        <div className="investor-relationship">
          <div
            class="accordion mx-1 mb-1 d-block d-md-none"
            id="accordionExample"
          >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Document
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="toggle-btn d-flex mt-3 ms-3">
                    <ToggleButton
                      inactiveLabel=""
                      activeLabel=""
                      colors={{
                        activeThumb: {
                          base: "#212C43",
                        },
                        inactiveThumb: {
                          base: "#fff",
                        },
                        active: {
                          base: "rgb(158 174 209)",
                          hover: "rgb(158 174 209)",
                        },
                        inactive: {
                          base: "rgb(189 191 194)",
                          hover: "rgb(189 191 194)",
                        },
                      }}
                      trackStyle={{ width: "40px", height: "17px" }}
                      thumbStyle={{
                        width: "22px",
                        height: "22px",
                        marginLeft: "-1px",
                        boxShadow: "rgb(0 0 0 / 30%) 0px 0px 5px 1px",
                      }}
                      value={value}
                      backgroundColor="#ED9E41"
                      onToggle={(value) => {
                        setValue(!value);
                      }}
                    />{" "}
                    <span style={{ marginLeft: "10px" }}>Year Wise</span>
                  </div>
                  {value ? (
                    <>
                      <ul className="list-group list-group-flush mt-4">
                        {yearData?.map((item) => {
                          return (
                            <li
                              key={item.id}
                              className={
                                "border-0 list-group-item " +
                                (yearId === item.id && "is-active")
                              }
                              onClick={() => setYearValue(item.id)}
                            >
                              {item?.name}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <ul className="list-group list-group-flush mt-4">
                      {reportsData?.map((item) => {
                        return (
                          <li
                            key={item.id}
                            className={
                              "border-0 list-group-item " +
                              (investorId === item.id && "is-active")
                            }
                            onClick={() => setInvestorValue(item.id)}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {otherReports?.map((item) => {
                    return (
                      <ul key={item.id} className="list-group list-group-flush">
                        <li
                          onClick={() => setOtherReportsdata(item.id)}
                          // className={"list-group-item" + ()}
                        >
                          {item.name}
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0 h-100">
            <div className="col-md-3 d-none d-md-block">
              <div className="toggle-btn d-flex mt-3 ms-3">
                <ToggleButton
                  inactiveLabel=""
                  activeLabel=""
                  colors={{
                    activeThumb: {
                      base: "#212C43",
                    },
                    inactiveThumb: {
                      base: "#fff",
                    },
                    active: {
                      base: "rgb(158 174 209)",
                      hover: "rgb(158 174 209)",
                    },
                    inactive: {
                      base: "rgb(189 191 194)",
                      hover: "rgb(189 191 194)",
                    },
                  }}
                  trackStyle={{ width: "40px", height: "17px" }}
                  thumbStyle={{
                    width: "22px",
                    height: "22px",
                    marginLeft: "-1px",
                    boxShadow: "rgb(0 0 0 / 30%) 0px 0px 5px 1px",
                  }}
                  value={value}
                  backgroundColor="#ED9E41"
                  onToggle={(value) => {
                    setValue(!value);
                  }}
                />{" "}
                <span style={{ marginLeft: "10px" }}>Year Wise</span>
              </div>
              {value ? (
                <>
                  <ul className="list-group list-group-flush mt-4">
                    {yearData?.map((item) => {
                      return (
                        <li
                          key={item.id}
                          className={
                            "border-0 list-group-item " +
                            (yearId === item.id && "is-active")
                          }
                          onClick={() => setYearValue(item.id)}
                        >
                          {item?.name}
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <ul className="list-group list-group-flush mt-4">
                  {reportsData?.map((item) => {
                    return (
                      <li
                        key={item.id}
                        className={
                          "border-0 list-group-item " +
                          (investorId === item.id && "is-active")
                        }
                        onClick={() => setInvestorValue(item.id)}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              )}
              {otherReports?.map((item) => {
                return (
                  <ul key={item.id} className="list-group list-group-flush">
                    <li
                      onClick={() => setOtherReportsdata(item.id)}
                      className={
                        "list-group-item " +
                        (otherId === item.id && "is-active")
                      }
                    >
                      {item.name}
                    </li>
                  </ul>
                );
              })}
            </div>
            <div className="col-12 col-md-9 main-section">
              <div className="container-fluid">
                <h2 className="text-white mb-4">Financial Result</h2>
              </div>
              <div className="container-fluid">
                <div
                  className={"row g-0" + (hideYearData && " d-none")}
                  style={{ margin: "auto" }}
                >
                  <div className="p-0 col-1 bg-white text-yellow fs-4 d-flex justify-content-center align-items-center rounded-start">
                    <FaChevronLeft className="review-swiper-button-prev" />
                  </div>
                  <div className="col-10 col-sm-10 p-0">
                    {value ? (
                      <div className="slider bg-white py-3 px-0">
                        <Swiper
                          modules={[Navigation, Pagination, Scrollbar, A11y]}
                          spaceBetween={50}
                          slidesPerView={3}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => console.log(swiper)}
                          navigation={{
                            nextEl: ".review-swiper-button-next",
                            prevEl: ".review-swiper-button-prev",
                          }}
                        >
                          {reportsData?.map((item) => {
                            return (
                              <SwiperSlide
                                key={item.id}
                                className={
                                  investorId === item.id && "is-active"
                                }
                                onClick={() => setInvestorValue(item.id)}
                              >
                                {item.name}
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    ) : (
                      <>
                        {hideYearData ? null : (
                          <div className="slider bg-white py-3 px-0">
                            <Swiper
                              modules={[
                                Navigation,
                                Pagination,
                                Scrollbar,
                                A11y,
                              ]}
                              // spaceBetween={3}
                              slidesPerView={1}
                              // centeredSlides="true"
                              navigation={{
                                nextEl: ".review-swiper-button-next",
                                prevEl: ".review-swiper-button-prev",
                              }}
                              onSwiper={(swiper) => console.log(swiper)}
                              onSlideChange={() => console.log("slide change")}
                              breakpoints={{
                                400: {
                                  slidesPerView: 2,
                                },
                                576: {
                                  // spaceBetween: 20,
                                  slidesPerView: 3,
                                },
                                768: {
                                  // spaceBetween: 30,
                                  slidesPerView: 3,
                                },
                                990: {
                                  // spaceBetween: 40,
                                  slidesPerView: 5,
                                },
                              }}
                            >
                              {yearData?.map((item, i) => {
                                return (
                                  <SwiperSlide
                                    key={item.id}
                                    className={
                                      yearId === item.id && "is-active"
                                    }
                                    onClick={() => setYearValue(item.id)}
                                  >
                                    {item.name}
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="p-0 col-1 bg-white text-yellow fs-4 d-flex justify-content-center align-items-center rounded-end">
                    <FaChevronRight className="review-swiper-button-next" />
                  </div>
                </div>
              </div>

              <div className="container-fluid result-container">
                <div className="result row g-0 g-sm-3 mt-4">
                  {showButton ? (
                    <div className="row">
                      <div className="d-block text-start m-3">
                        <button
                          onClick={() => {
                            setBack();
                          }}
                          className="btn btn-light"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  ) : null}
                  {data ? (
                    <>
                      {Object.keys(data)?.map((key) => {
                        return (
                          <>
                            {typeof data[key] === "string" ? (
                              data[key] !== "" ? (
                                <>
                                  <div className="col-12 col-sm-6">
                                    <a
                                      href={data[keyObject]}
                                      target="_blank"
                                      className="text h-100"
                                      rel="noopener noreferrer"
                                    >
                                      <div>
                                        <a
                                          href={data[key]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <div class="card text-center mb-3 h-100">
                                            <div class="card-body p-5">
                                              <h6 class="card-title m-0">
                                                {key}
                                              </h6>
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    </a>
                                  </div>
                                </>
                              ) : null
                            ) : (
                              <>
                                {hideObject ? (
                                  <div
                                    className="col-5"
                                    onClick={() => {
                                      setObj(key);
                                    }}
                                  >
                                    <h5>
                                      <a>{key}</a>
                                    </h5>
                                    {showObject ? (
                                      <>
                                        {keyObject === key ? (
                                          <>
                                            {Object.keys(data[key]).map((i) => (
                                              <a
                                                href={data[keyObject][i]}
                                                target="_blank"
                                                className="col-5 text"
                                                rel="noopener noreferrer"
                                              >
                                                <div>
                                                  {data[key][i] !== "" ? (
                                                    <h5>
                                                      <a
                                                        href={data[key][i]}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {i}
                                                      </a>
                                                    </h5>
                                                  ) : null}
                                                </div>
                                              </a>
                                            ))}
                                          </>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                ) : (
                                  <>
                                    {keyObject === key ? (
                                      <>
                                        {Object.keys(data[keyObject]).map(
                                          (i) => (
                                            <>
                                              {data[key][i] !== "" ? (
                                                <a
                                                  href={data[keyObject][i]}
                                                  target="_blank"
                                                  className="col-5 text"
                                                  rel="noopener noreferrer"
                                                >
                                                  <div>
                                                    <h5>
                                                      <a
                                                        href={
                                                          data[keyObject][i]
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {i}
                                                      </a>
                                                    </h5>
                                                  </div>
                                                </a>
                                              ) : null}
                                            </>
                                          ),
                                        )}
                                      </>
                                    ) : null}
                                  </>
                                )}
                                {/* // </div> */}
                              </>
                            )}
                          </>
                        );
                      })}

                      {/* {Object.values(data)[0] ? <div className="col-5">
                <h5><a href={Object.values(data)[0]} target="_blank">{Object.keys(data)[0]}</a></h5>
              </div>
                :
                null}

              {Object.values(data)[1] ? <div className="col-5">
                <h5><a href={Object.values(data)[1]} target="_blank">{Object.keys(data)[1]}</a></h5>
              </div>
                :
                null}

              {Object.values(data)[2] ? <div className="col-5">
                <h5><a href={Object.values(data)[2]} target="_blank">{Object.keys(data)[2]}</a></h5>
              </div>
                :
                null}

              {Object.values(data)[3] ? <div className="col-5">
                <h5><a href={Object.values(data)[3]} target="_blank">{Object.keys(data)[3]}</a></h5>
              </div>
                :
                null} */}
                    </>
                  ) : (
                    <p className="text-white text-center">No Data Published</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          <PuffLoader
            color="#ED9E41"
            loading={loading}
            // cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="loader"
          />
        </div>
      )}
    </>
  );
};

export default InvestorRelationship;
