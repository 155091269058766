import matchers from '@testing-library/jest-dom/matchers';
import * as Yup from 'yup';

const onlyAlphabets = /^[A-Za-z ]+$/;
const phonePattern= /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const inquiryFormSchema = Yup.object().shape({
    title: Yup.string()
    .matches(onlyAlphabets, 'Only alphabets and spaces are allowed'),
    // .required('Company name is reuired'),
    
    first_name: Yup.string()
    .matches(onlyAlphabets, 'Only alphabets and spaces are allowed')
    .required('Name is required'),

    primary_contact_number: Yup.string()
    // .matches(phonePattern, 'Invalid Contact Number')
    .required('Contact Number is required')
})