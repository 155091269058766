import React from "react";
import aboutUs from "../../assets/about-us.png";
import img from "../../assets/images.jpeg";
import jimin from "../../assets/bts-jimin-stripes_1647375171.jpg";
import section3 from "../../assets/cecbd52042c9e8b85e32d75fb59d80b9.jpg";
import team from "../../assets/about-team.png";

import AshokkumarGupta from "../../assets/Ashokkumar Gupta Rudra Global.jpg";
import NikhilGupta from "../../assets/Nikhil Gupta Rudra Global.jpg";
import SahilGupta from "../../assets/Sahil Gupta Rudra Global.jpg";
import VivekTyagi from "../../assets/Vivek Tyagi Rudra Global.jpg";

import img1 from "../../assets/img-1.jpeg";
import img2 from "../../assets/img-2.jpeg";
import img3 from "../../assets/img-3.jpeg";
import img4 from "../../assets/our-mission.png";
import cs1 from "../../assets/strong division leadership.png";
import cs2 from "../../assets/Vision for Enhancement.png";
import cs3 from "../../assets/Integrated Plant.png";
import cs4 from "../../assets/Solid Foundation.png";
import { Helmet } from "react-helmet";
import "../../css/about.css";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Rudra Global Infra Products, Bhavanagar</title>
      </Helmet>
      <div>
        <section className="about-us">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6 d-flex justify-content-center align-items-center">
                <div className="row justify-content-center">
                  <div className="col-7 col-md-7 col-lg-12">
                    {/* <div className="container"> */}
                    <img src={aboutUs} alt="" className="img-fluid" />
                    {/* </div> */}
                  </div>
                  <div className="col-7 col-md-7 col-lg-12 text-end">
                    {/* <div className="container"> */}
                    <h1 className="display-4 about-us-title">About Us</h1>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6 d-flex justify-content-center align-items-center">
                <div className="row g-3">
                  <div className="col-12">
                    <div className="container">
                      <p className="text-justify">
                        Rudra Global is promoted by a team of young
                        entrepreneurs and experienced veterans with proven track
                        records. The group started its journey in 1991 with a
                        steel re-rolling mill having capacity of 1000 MT per
                        month. Today, the group is engaged in various businesses
                        like Ship recycling, oxygen plant, Induction Furnace,
                        Re-rolling mill, producing more than 2 Lakh MT per annum
                        of steel, with a group turnover around 1000 cr per
                        annum. "It has a perfect blend" of youth and experienced
                        individuals dedicated to make world-class steel
                        products.
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="container">
                      <p className="text-justify">
                        Fulfilling its objective of consistence growth in
                        quality steel manufacturing, Rudra Global is launching
                        its premiere TMT Brand" Rudra TMX" manufactured from
                        Premium Quality Billets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="timeline py-5">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <div className="container">
                  <h1 className="display-4 about-us-title">Timeline</h1>
                </div>
              </div>
            </div>

            <div className="row gy-3">
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2013</h6>
                    <h5 class="mb-0">2nd February, 2013</h5>
                    <p class="card-text m-0">
                      Commencement of Billet Production
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2014</h6>
                    <h5 class="mb-0">29th March, 2014</h5>
                    <p class="card-text m-0">
                      Launch of Brand Rudra with Rudra TMX
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2015</h6>
                    <h5 class="mb-0">1st April, 2015</h5>
                    <p class="card-text mb-3">Rudra Wire Launched</p>

                    <h5 class="mb-0">16th July 2015</h5>
                    <p class="card-text m-0">
                      M.D Inducto Cast Listed on BSE SME
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2016</h6>
                    <h5 class="mb-0">3rd March, 2016</h5>
                    <p class="card-text mb-3">
                      Tie-up of Franchise Unit for TMT Bars
                    </p>

                    <h5 class="mb-0">1st April, 2016</h5>
                    <p class="card-text m-0">Rudra Pipe Launched</p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2017</h6>
                    <h5 class="mb-0">October, 2017</h5>
                    <p class="card-text mb-3">Migrated to BSE Mainboard</p>

                    <h5 class="mb-0">December 2017</h5>
                    <p class="card-text m-0">
                      First order for Export Received Unit for TMT Bars
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2018</h6>
                    <h5 class="mb-0">January, 2018</h5>
                    <p class="card-text mb-3">
                      First Rudra Infra Mart Launched
                    </p>

                    <h5 class="mb-0">9th February,2018</h5>
                    <p class="card-text m-0">Rudra Cement Launched</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2019</h6>
                    <h5 class="mb-0">April, 2019</h5>
                    <p class="card-text mb-3">
                      Installation of 20 MT Induction Furnace
                    </p>

                    <h5 class="mb-0">September, 2019</h5>
                    <p class="card-text m-0">
                      Launch of Multi-Brand Marketing network Someshwar TMT
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2021</h6>
                    <p class="card-text m-0">
                      Addition of 2 more Brands to Multi-Brand Marketing JB 500
                      TMX & Tridev TMX
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="mb-3 fw-bold btn-yellow">2023</h6>
                    <p class="card-text m-0">
                      Rolling mill installed to cater to rising demand of TMT
                      bars
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-mission py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 text-start text-md-start text-lg-start d-flex flex-column justify-content-center">
                <div className="container">
                  <h1 className="display-4 about-us-title">
                    OUR <br /> MISSION
                  </h1>
                  <p className="text-white">
                    The Promoters Mission is to cater Pan-India Steel Market by
                    2020.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="container">
                  <div className="row gy-3 pt-4 pt-md-0 pt-lg-0 images-section">
                    <div className="col-6">
                      <img src={img4} alt="" className="img-fluid" />
                    </div>
                    <div className="col-6">
                      <img src={img2} alt="" className="img-fluid" />
                    </div>
                    <div className="col-6">
                      <img src={img3} alt="" className="img-fluid" />
                    </div>
                    <div className="col-6">
                      <img src={img1} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="company-strength py-5">
          <div className="container">
            <div className="row mb-5">
              <div className="col-12">
                <div className="container">
                  <h1 className="display-4 about-us-title">Company Strength</h1>
                </div>
              </div>
            </div>

            <div className="row g-4">
              <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                <div className="h-100">
                  <div class="card h-100">
                    <img src={cs1} class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Strong decisive leadership</h5>
                      <p class="card-text">
                        Working with appropriate information and the help of
                        trained workforce and making timely decisions adding to
                        the development of company, effectively executing plans,
                        and achieving results with the goal to maintain and
                        enhance investor’s confidence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                <div className="h-100">
                  <div class="card h-100">
                    <img src={cs2} class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Vision for Enhancement</h5>
                      <p class="card-text">
                        Ready to take steps for advancement increasing our
                        production capabilities to fulfil the demand and vision
                        for remaining at the forefront of the steel industry
                        always with emendations in company infrastructure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                <div className="h-100">
                  <div class="card h-100">
                    <img src={cs3} class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Integrated Plant</h5>
                      <p class="card-text">
                        Clever investments are made for the company's growth,
                        with a modernized setup that increases work productivity
                        with the help of developing cut-edge technology for
                        establishing competitive edge. Is Infrastructure
                        Resilient with ability to withstand.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                <div className="h-100">
                  <div class="card h-100">
                    <img src={cs4} class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Solid Foundation</h5>
                      <p class="card-text">
                        A consistent and recognizable company experiences,
                        offering services and best products with the lengthy
                        track record along with offering wide range of networks.
                        Taking strong decisions keeping our vision in mind and
                        working on it with the modern technology.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-team our-team-bg py-5">
          <div className="container">
            <div className="row our-team-info">
              <div className="col-12 col-md-4 col-lg-3 text-center text-md-center text-lg-start">
                <div className="container">
                  <img
                    src={NikhilGupta}
                    alt="Nikhil Gupta Rudra Global"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className="container">
                  <div className="row gy-3 pt-4 pt-md-0 pt-lg-0">
                    <div className="col-12 text-start text-md-start text-lg-start">
                      <h1 className="display-6 team-name">Nikhil Gupta</h1>
                      <h4 className="team-role">Founder</h4>
                    </div>
                    <div className="col-12">
                      <p>
                        Mr. Nikhil Gupta has served as the Founder at Rudra
                        Global Infra Products Ltd up to May 2021. A natural
                        leader, it is under his leadership that the Rudra Group
                        has been able to make a name for itself in the industry.
                        An aggressive approach to work with a never to say die
                        attitude have been the key attributes to his success.
                      </p>
                    </div>
                    <div className="col-12">
                      <p>
                        To his credit, he has the honour of being the youngest
                        Joint Secretary of Alang-Sosiya Ship Recycling
                        Association, rendering his services for 6 years. His
                        deep knowledge of the iron and steel industry has
                        enabled him to be a representative of his industry as a
                        Speaker at various conferences in India and Abroad.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-team py-5">
          <div className="container">
            <div className="row our-team-info">
              <div className="col-12 col-md-4 col-lg-3 text-center text-md-center text-lg-start">
                <div className="container">
                  <img
                    src={AshokkumarGupta}
                    alt="Ashokkumar Gupta Rudra Global"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className="container">
                  <div className="row gy-3 pt-4 pt-md-0 pt-lg-0">
                    <div className="col-12 text-start text-md-start text-lg-start">
                      <h1 className="display-6 team-name">Ashok Kumar Gupta</h1>
                      <h4 className="team-role">Chairman</h4>
                    </div>
                    <div className="col-12">
                      <p>
                        Mr. Ashok Gupta currently serves as the Chairman of BOD
                        at Rudra Global Infra Products Ltd. He is a visionary in
                        the field of iron and steel sector, and therefore has a
                        proven record of achieving success in all his endeavors.
                      </p>
                    </div>
                    <div className="col-12">
                      <p>
                        In the late 1990s Mr. Ashok Gupta ventured into ship
                        breaking business in Alang, Bhavnagar and set up Hari
                        Krishna Steel Corporation – a now sister concern of
                        Rudra Global Infra Products Ltd. His able management and
                        untiring hard work has not only allowed the business to
                        sail through testing times but also has provided his
                        next generation with a solid platform to aim for higher
                        goals in business.
                      </p>
                    </div>
                    <div className="col-12">
                      <p>
                        Mr. Ashok Gupta’s wisdom, backed up his apt guidance to
                        fellow co-workers and his unconditional support to his
                        next generation, has been instrumental in the Rudra
                        Group achieving these heights.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-team our-team-bg py-5">
          <div className="container">
            <div className="row our-team-info">
              <div className="col-12 col-md-4 col-lg-3 text-center text-md-center text-lg-start">
                <div className="container">
                  <img
                    src={SahilGupta}
                    alt="Sahil Gupta Rudra Global"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className="container">
                  <div className="row gy-3 pt-4 pt-md-0 pt-lg-0">
                    <div className="col-12 text-start text-md-start text-lg-start">
                      <h1 className="display-6 team-name">Mr. Sahil Gupta</h1>
                      <h4 className="team-role">Managing Director</h4>
                    </div>
                    <div className="col-12">
                      <p>
                        Mr. Sahil Gupta, currently serves as the Managing
                        Director at Rudra Global Infra Products Ltd. Mr. Sahil
                        Gupta’s insights have been instrumental in improving
                        productivity across different departments and eventually
                        moving up the hierarchical chain.
                      </p>
                    </div>
                    <div className="col-12">
                      <p>
                        With a vision to make Rudra Global Infra the top player
                        in its domain, Mr. Sahil Gupta is absolutely determined
                        to bring about a revolution in the field of TMT Bars and
                        continue the legacy of the brand – RUDRA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-team py-5">
          <div className="container">
            <div className="row our-team-info">
              <div className="col-12 col-md-4 col-lg-3 text-center text-md-center text-lg-start">
                <div className="container">
                  <img
                    src={VivekTyagi}
                    alt="Vivek Tyagi Rudra Global"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className="container">
                  <div className="row gy-3 pt-4 pt-md-0 pt-lg-0">
                    <div className="col-12 text-start text-md-start text-lg-start">
                      <h1 className="display-6 team-name">Vivek Tyagi</h1>
                      <h4 className="team-role">CEO</h4>
                    </div>
                    <div className="col-12">
                      <p>
                        Mr. Vivek Tyagi currently serves as Chief Executive
                        Officer at Rudra Global Infra Products Ltd. Mr. Tyagi
                        has helped the company establish Rudra as big a brand it
                        is known today. His endeavours were not only restricted
                        to the primary product of the company, but following the
                        franchise model, he went on to add Cement, 6mm bars, ERW
                        Pipes and MS wires, to company’s product portfolio.
                      </p>
                    </div>
                    <div className="col-12">
                      <p>
                        His hard work and constant drive to learn and develop
                        himself, led him to getting the managements nod in
                        managing the marketing department of the company in the
                        states of Tamil Nadu and Karnataka, from March 2008.
                      </p>
                    </div>
                    <div className="col-12">
                      <p>
                        In 2009, he was called back to Gujarat to help the
                        company establish a new sales and distribution channel,
                        which was based on distributor dealer network.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
