import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaBars } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import "../css/animate.css";
// import "../css/style.css";
import "../css/common.css";
import brandLogo from "../assets/logo.png";
import brandFavicon from "../assets/fab icon.png";
import favicon from "../assets/fav-icon.jpg";
import brandLogofff from "../assets/rudra white logo.png";
import Home from "../modules/home/Home";
import TMTProduct from "../modules/tmt-product/TMTProduct";
import AboutUs from "../modules/about-us/AboutUs";
import Media from "../modules/media/Media";
import ContactUs from "../modules/contact-us/ContactUs";
import InvestorRelationship from "../modules/investor-relationship/InvestorRelationship";
import Dealership from "../modules/dealership/Dealership";
import ScrollToTop from "./ScrollTop";

const Header = () => {
  const [contactHeader, setContactHeader] = useState(false);
  const [homeHeader, setHomeHeader] = useState(false);
  const [overlay, setOverlay] = useState(true);
  const param = useParams();
  const url = window.location.href;
  const lastSegment = url.split("/").pop();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    console.log("dshgkjhdsg");
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (lastSegment === "contact-us") {
      setContactHeader(true);
    } else if (lastSegment === "") {
      setHomeHeader(true);
    } else {
      setContactHeader(false);
      setHomeHeader(false);
    }

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = (e) => {
    const header = document.querySelector("#navbarMain");
    const scrollTop = window.scrollY;
    scrollTop >= 50 ? setheader(header) : onTop(header);
  };

  const setheader = (header) => {
    header.classList.add("is-sticky");
    setHomeHeader(false);
  };

  const onTop = (header) => {
    const url = window.location.href;
    const segment = url.split("/").pop();
    header.classList.remove("is-sticky");
    if (segment === "contact-us") {
      setContactHeader(true);
    } else if (segment === "") {
      setHomeHeader(true);
    } else {
      setContactHeader(false);
      setHomeHeader(false);
    }
  };

  return (
    <>
      <div className="application">
        <Helmet>
          <title>Rudra TMX</title>
          <link rel="icon" type="image/png" href={favicon} />
          {/* <link rel="canonical" href={logo} /> */}
        </Helmet>
      </div>
      <div className={contactHeader ? "contact-header" : ""}>
        <Router>
          <ScrollToTop>
            <nav
              className={
                homeHeader
                  ? "navbar navbar-expand-xl fixed-top home-header"
                  : "navbar navbar-expand-xl fixed-top"
              }
              id="navbarMain"
            >
              <div class="container-fluid">
                <NavLink to="">
                  <a class="navbar-brand ms-3 ms-sm-5">
                    <img
                      src={homeHeader ? brandLogofff : brandLogo}
                      alt="brand-logo"
                      height={"40px"}
                      onClick={() => setHomeHeader(true)}
                    />
                  </a>
                </NavLink>
                <button
                  class="navbar-toggler me-sm-5"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div
                  class="offcanvas offcanvas-end"
                  tabindex="-1"
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                >
                  <div class="offcanvas-header pb-0 px-4 pt-5">
                    <img
                      src={brandLogo}
                      alt=""
                      className="img-fluid"
                      id="offcanvasNavbarLabel"
                    />
                    <div className="container d-flex justify-content-end align-items-center">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <hr className="d-block d-xl-none" />
                  <div class="offcanvas-body px-4 py-0">
                    <ul
                      class="navbar-nav justify-content-end flex-grow-1 pe-3"
                      id="Menu"
                    >
                      <li
                        class="nav-item"
                        onClick={() => [
                          setContactHeader(false),
                          setHomeHeader(false),
                        ]}
                      >
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink to="about-us" className="nav-link">
                            About Us
                          </NavLink>
                        </span>
                      </li>

                      <li
                        class="nav-item"
                        onClick={() => [
                          setContactHeader(false),
                          setHomeHeader(false),
                        ]}
                      >
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="rudra-tmt-bars-product"
                            className="nav-link"
                          >
                            TMT Products
                          </NavLink>
                        </span>
                      </li>

                      <li
                        class="nav-item"
                        onClick={() => [
                          setContactHeader(false),
                          setHomeHeader(false),
                        ]}
                      >
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink to="dealership" className="nav-link">
                            Dealership
                          </NavLink>
                        </span>
                      </li>

                      <li
                        class="nav-item"
                        onClick={() => [
                          setContactHeader(false),
                          setHomeHeader(false),
                        ]}
                      >
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="investor-relationship"
                            className="nav-link"
                          >
                            Investor Relationship
                          </NavLink>
                        </span>
                      </li>

                      {/* <li class="nav-item"
                        onClick={() => [
                          setContactHeader(false),
                          setHomeHeader(false),
                        ]}
                      >
                          <span data-bs-dismiss="offcanvas" aria-label="Close">
                            <NavLink to="media" className="nav-link">
                            Media
                            </NavLink>
                          </span>
                        
                        
                      </li> */}

                      <li
                        class="nav-item"
                        onClick={() => [
                          setContactHeader(false),
                          setHomeHeader(false),
                        ]}
                      >
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink to="contact-us" className="nav-link">
                            Contact Us
                          </NavLink>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>

            {/* <div className={homeHeader ? "header home-header" : "header"}>
                <div className="brand">
                  <NavLink to="">
                    <img
                      src={homeHeader ? brandLogofff : brandLogo}
                      alt="brand-logo"
                      onClick={() => setHomeHeader(true)}
                    />
                  </NavLink>
                </div>
                <nav>
                  <ul>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                      ]}
                    >
                      <NavLink to="about-us">About Us</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                      ]}
                    >
                      <NavLink to="rudra-tmt-bars-product">TMT Product</NavLink>
                    </li>
                    <li onClick={() => [setContactHeader(false), setHomeHeader(false)]}><NavLink to="dealership">Dealership</NavLink></li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                      ]}
                    >
                      <NavLink to="investor-relationship">Investor Relationship</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                      ]}
                    >
                      <NavLink to="media">Media</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(true),
                        setHomeHeader(false),
                      ]}
                    >
                      <NavLink to="contact-us">Contact Us</NavLink>
                    </li>
                    <li className="icon" onClick={toggleDrawer}>
                      <FaBars />
                    </li>
                  </ul>
                </nav>
              </div> */}

            {/* <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction='right'
              className='bla bla bla'
            >
              <div className="sidenav-drawer">
                <div className="top-header d-flex align-items-center justify-content-between">
                  <img src={brandFavicon} alt="logo" />
                  <a className="fa-icon" onClick={toggleDrawer}>&times;</a>
                </div>
                <div className="hr-line"></div>

                <div className="nav-content">
                  <ul>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setOverlay(true),
                        toggleDrawer()
                      ]}
                    >
                      <NavLink to="about-us">About Us</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setOverlay(true),
                        toggleDrawer()
                      ]}
                    >
                      <NavLink to="rudra-tmt-bars-product">TMT Product</NavLink>
                    </li>
                    <li onClick={() => [setContactHeader(false), setHomeHeader(false), toggleDrawer()]}><NavLink to="dealership">Dealership</NavLink></li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setOverlay(true),
                        toggleDrawer()
                      ]}
                    >
                      <NavLink to="investor-relationship">Investor Relationship</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setOverlay(true),
                        toggleDrawer()
                      ]}
                    >
                      <NavLink to="media">Media</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(true),
                        setHomeHeader(false),
                        setOverlay(true),
                        toggleDrawer()
                      ]}
                    >
                      <NavLink to="contact-us">Contact Us</NavLink>
                    </li>
                  </ul>
                </div>
              </div> */}
            {/* <div id="myNav" className="overlay">
                <a href="" className="closebtn" onClick={() => setOverlay(true)}>
                  &times;
                </a>

                <div className="overlay-content">
                  <ul>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setOverlay(true)
                      ]}
                    >
                      <NavLink to="about-us">About Us</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setOverlay(true)
                      ]}
                    >
                      <NavLink to="rudra-tmt-bars-product">TMT Product</NavLink>
                    </li>
                    <li onClick={() => [setContactHeader(false), setHomeHeader(false)]}><NavLink to="dealership">Dealership</NavLink></li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setOverlay(true)
                      ]}
                    >
                      <NavLink to="investor-relationship">Investor Relationship</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setOverlay(true)
                      ]}
                    >
                      <NavLink to="media">Media</NavLink>
                    </li>
                    <li
                      onClick={() => [
                        setContactHeader(true),
                        setHomeHeader(false),
                        setOverlay(true)
                      ]}
                    >
                      <NavLink to="contact-us">Contact Us</NavLink>
                    </li>
                  </ul>
                </div>
              </div> */}
            {/* </Drawer> */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="rudra-tmt-bars-product" element={<TMTProduct />} />
              <Route path="dealership" element={<Dealership />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="media" element={<Media />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route
                path="investor-relationship"
                element={<InvestorRelationship />}
              />
            </Routes>
          </ScrollToTop>
        </Router>
      </div>
      <ToastContainer
      // position="top-right"
      // autoClose={5000}
      // hideProgressBar={false}
      // newestOnTop={false}
      // closeOnClick
      // rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover
      />
    </>
  );
};

export default Header;
