import http from "../environment";

const saveDealerInquiry = (data) => {
    return http.post(`inquiry/save`, data);
}

const sendEmail = (data) => {
    return http.post(`send/email`, data);
}

const sendSMS = (data) => {
    return http.post(`sms/email`, data);
}

const thankU = (data) => {
    return http.post('send/ty/sms', data);
}

const sendInquirySMS = (data) => {
    return http.post(`send/inquiry/sms`, data);
}

const InquiryService = {
    saveDealerInquiry,
    sendEmail,
    sendSMS,
    thankU,
    sendInquirySMS
}

export default InquiryService;