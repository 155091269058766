import http from "../environment";

const getAllYear = () => {
    return http.get(`year/get`);
}

const getAllReports = () => {
    return http.get(`investor/master/get`);
}
const getOtherReports = () => {
    return http.get(`investor/master/other/data/get`);
}

const getCategorizedData = (yearId, investorId) => {
    return http.get(`investor/get?financial_year=${yearId}&investors_id=${investorId}`);
}

const getInvestorData = (id) => {
    return http.get(`/investor/get?investors_id=${id}`);
}

const InvestorService = {
    getAllYear,
    getAllReports,
    getCategorizedData,
    getOtherReports,
    getInvestorData
}

export default InvestorService;