import React, { useEffect, useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import Iframe from "react-iframe";
import MediaService from "../../api-config/Media";

import PuffLoader from "react-spinners/PuffLoader";

import "../../css/media.css"

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#ED9E41",
};

const Media = () => {
  const [achievements, setAchievements] = useState();
  const [advertisement, setAdvertisement] = useState();
  const [launchImg, setLaunchImg] = useState([]);
  const [launchVid, setLaunchVid] = useState([]);

  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    MediaService.getAchivements()
      .then((res) => {
        console.log(res.data.data);
        setAchievements(res.data.data[0]);
      })
      .catch((e) => console.log(e));
    MediaService.getLaunch()
      .then((res) => {
        console.log(res.data.data[0])
        for (let i = 0; i < res.data.data[0].image.length; i++) {
          console.log(res.data.data[0].image[i])
          const lastSegment = res.data.data[0].image[i].split(".").pop();
          if (lastSegment === 'png' || lastSegment === 'jpeg' || lastSegment === 'jpg') {
            setLaunchImg(prev => [...prev, res.data.data[0].image[i]]);
          } else {
            setLaunchVid(prev => [...prev, res.data.data[0].image[i]])
          }
        }
      })
      .catch(e => console.log(e));
    MediaService.getAdvertisement().then((res) => {
      setAdvertisement(res.data.data[0]);
    })
      .catch(e => console.log(e));
  }, []);

  console.log(launchVid);

  return (
    <>
      <Helmet>
        <title>Rudra Global Media Gallery and Achievements</title>
      </Helmet>
      <Suspense
        fallback={<h1>Loading profile...</h1>}
      >
        {!loading ?
          <div className="media">
            <div className="section-1">
              <h1 className="main-heading pt-4">Achievements</h1>
              <img src={achievements?.image[0]} alt="" />
            </div>
            <div className="section-2 launch">
              <h1 className="main-heading">Launch</h1>
              <div>
                {/* <div className="row main-row">
              <div className="col-12 col-md-12 col-lg-5 col-xl-5">
                <div className="row">
                  <div className="col">
                    <Iframe
                      url="https://www.youtube.com/embed/kWCjtTm2FAc"
                      width="640px"
                      height="320px"
                      id=""
                      className="iframe1"
                      display="block"
                      position="relative" />
                  </div>
                  <div className="col">
                    <img src={launch1} className="launch-img" alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Iframe
                      url="https://www.youtube.com/embed/rlhBD-tcTDk"
                      width="640px"
                      height="320px"
                      id=""
                      className="iframe1"
                      display="block"
                      position="relative" />
                  </div>
                  <div className="col">
                    <img src={launch2} className="launch-img" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                <Iframe
                  url="https://www.youtube.com/embed/6HrfgAfCHq4"
                  width="640px"
                  height="320px"
                  id=""
                  className="middle-iframe"
                  display="block"
                  position="relative" />
              </div>
              <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                <div className="row">
                  <div className="col">
                    {" "}
                    <img src={launch1} className="launch-img" alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <img src={launch3} className="launch-img" alt="" />
                  </div>
                </div>
              </div>
            </div> */}
                <div className="row main-row">
                  {launchImg?.map((item) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <div class="image-container">
                          <img src={item} className="launch-img" alt="" />
                          <div class="after"></div>
                        </div>
                      </div>
                    );
                  })}
                  {launchVid?.map((item) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <div class="image-container">
                          <Iframe
                            url={item}
                            width="640px"
                            height="320px"
                            id=""
                            className="iframe1"
                            display="block"
                            position="relative" />
                          <div class="after"></div>
                        </div>
                      </div>
                    )
                  })}
                </div>

              </div>
            </div>
            <div className="section-2">
              <br />
              <div>
                <h1 className="main-heading">Advertisement</h1>
                <div className="row advertisement">
                  <div className="col-12 col-md-12 col-lg-4 col-xl-4">
                    <div class="image-container">
                      <img src={advertisement?.image[0]} alt="" className="side-img" />
                      <div class="after"></div>
                    </div>

                  </div>
                  <div className="col-12 col-md-12 col-lg-7 col-xl-7">
                    <div className="row">
                      {advertisement?.image?.slice(1).map((item, i) => {
                        return (
                          <div className="col-6 col-md-6 col-lg-4 col-xl-4">
                            <div class="image-container">
                              <img src={item} alt="" className="add-img" />
                              <div class="after"></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="spinner-container">
          <PuffLoader
            color="#ED9E41"
            loading={loading}
            // cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="loader"
          />
          </div>
        }
      </Suspense>
    </>
  );
};

export default Media;
