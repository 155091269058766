import React from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import InquiryService from "../../api-config/Inquiry";
import { inquiryFormSchema } from "../../validations/InquiryForm";

import img1 from "../../assets/rudra-benefits.jpg";
import img2 from "../../assets/MicrosoftTeams-image (2).png";
import img3 from "../../assets/MicrosoftTeams-image (3).png";
import img4 from "../../assets/MicrosoftTeams-image.png";
import rudraMember from "../../assets/rudra-member.jpg";
import { Helmet } from "react-helmet";

import "../../css/dealership.css";

const Dealership = () => {
  const formik = useFormik({
    initialValues: {
      title: "",
      first_name: "",
      last_name: "xyz",
      primary_contact_number: "",
      message: "",
      status: "false",
      is_dealer: true,
    },
    validateOnChange: true,
    // enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      InquiryService.saveDealerInquiry(values)
        .then((res) => {
          if (res.data.success) {
            InquiryService.sendEmail(values).then((res) => {
              console.log(res);
            });
            // InquiryService.sendSMS(values).then((res) => {
            //   console.log(res);
            // });
            resetForm();
            toast.success(res.data.message, { autoClose: 1000 });
          } else {
            toast.error(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((e) => console.log(e));

      const requestData = {
        title: values?.title ?? "",
        first_name: values?.first_name ?? "",
        primary_contact_number: values?.primary_contact_number ?? "",
      };
      InquiryService.thankU(requestData)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, { autoClose: 1000 });
          } else {
            toast.error(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((e) => console.log(e));

      const data = {
        title: values?.title ?? "",
        first_name: values?.first_name ?? "",
        primary_contact_number: values?.primary_contact_number ?? "",
        message: values?.message ?? "",
        status: values.status,
        is_dealer: values.is_dealer,
        inquiry_type: "Dealer Inquiry",
      };

      InquiryService.sendInquirySMS(data)
        .then((res) => {
          console.log(res);
        })
        .catch((e) => console.log(e));
    },
    validationSchema: inquiryFormSchema,
    validateOnBlur: true,
  });

  const { handleChange, handleSubmit } = formik;

  return (
    <>
      <Helmet>
        <title>
          Rudra TMX Dealer Registration | Bhavanagar | Ahmedabad | Gujarat
        </title>
      </Helmet>
      <div className="dealership">
        <div className="section-1">
          <div className="container">
            <div className="row g-4 justify-content-center align-items-center">
              <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                <img src={img1} alt="rudra-benefits" className="img-fluid" />
              </div>
              <div className="col-1 d-none d-md-block"></div>
              <div className="col-12 col-md-12 col-lg-5 col-xl-5 m-0">
                <h1 className="display-3 fw-bold">
                  Rudra <br /> Exclusive <br /> Benefits.
                </h1>
                <p className="fs-3 m-0">SHREE RAM STEEL, RAJULA</p>
                <p className="fs-3">
                  Won the Premuim Bike For Top Performer Dealer
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section-2">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 text-white d-flex flex-column justify-content-center">
                <h1 className="display-4">
                  Become A <br /> Rudra Member
                </h1>
                <p className="fs-5">
                  RUdra Dealership Arrives With <br /> A Bouque Of Exclusive
                  Benefits.
                </p>
              </div>
              <div className="col-12 d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-4 col-sm-3 member-item">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="img-container mb-2">
                        <img src={img4} alt="" className="img-fluid" />
                      </div>
                      <h6 className="text-center">
                        Exclusive <br /> Localised <br /> Dealership
                      </h6>
                    </div>
                  </div>
                  <div className="col-4 col-sm-3 member-item">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="img-container mb-2">
                        <img src={img2} alt="" className="img-fluid" />
                      </div>
                      <h6 className="text-center">
                        Premium <br /> Bikes
                      </h6>
                    </div>
                  </div>
                  <div className="col-4 col-sm-3 member-item">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="img-container mb-2">
                        <img src={img3} alt="" className="img-fluid" />
                      </div>
                      <h6 className="text-center">
                        Regular <br /> Gifting
                      </h6>
                    </div>
                  </div>
                  <div className="col-4 col-sm-3 member-item d-none d-sm-block">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="img-container mb-2">
                        <img src={img3} alt="" className="img-fluid" />
                      </div>
                      <h6 className="text-center">
                        Exciting <br /> Gifting
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-3 py-5">
          <div className="container">
            <div className="row gy-5 gy-lg-0 justify-content-center align-items-center">
              <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                <h1 className="display-4 fw-bold mb-5">
                  Who Can Become <br /> A Dealer
                </h1>
                <p>
                  {" "}
                  <span className="me-2">-</span> Individuals / Sole
                  Proprietors.
                </p>
                <p>
                  {" "}
                  <span className="me-2">-</span> Infra Product Dealers.
                </p>
                <p>
                  {" "}
                  <span className="me-2">-</span> Construction Contractors.
                </p>
                <p>
                  {" "}
                  <span className="me-2">-</span> Real State Developers
                </p>
                {/* <ul className="dashed-bullets fs-5">
                            <li>Individuals / Sole Proprietors.</li>
                            <li>Infra Product Dealers.</li>
                            <li>Construction Contractors.</li>
                            <li>Real State Developers</li>
                        </ul> */}
              </div>
              <div className="col-12 col-md-12 col-lg-5 col-xl-5">
                <img src={rudraMember} alt="rudra-benefits" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="section-3">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <h1 className="main-heading fw-7">Who Can Become <br /> A Dealer</h1>
                        <ul>
                            <li className="bold-text">Individuals / Sole Proprietors.</li>
                            <li className="bold-text">Infra Product Dealers.</li>
                            <li className="bold-text">Construction Contractors.</li>
                            <li className="bold-text">Real State Developers</li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <img src={rudraMember} alt="rudra-member" />
                    </div>
                </div>
            </div> */}

        <section className="section-4 py-5">
          <div className="container">
            <div className="row">
              <div className="row dealership-mobile mt-5">
                <div className="col-12">
                  <h1 className="display-4 fw-bold text-white">Questions!!</h1>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-5 mt-3 mt-md-0">
                <div className="container mt-5">
                  <form class="row g-3 p-3" onSubmit={handleSubmit}>
                    <div class="col-12">
                      <label htmlFor="title" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        aria-describedby="title"
                        value={formik?.values.title}
                        onChange={handleChange}
                      />
                      {/* <p className="text-danger">{formik?.errors?.title}</p> */}
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                      <label htmlFor="first_name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        value={formik?.values.first_name}
                        onChange={handleChange}
                      />
                      {/* <p className="text-danger">{formik?.errors?.first_name}</p> */}
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                      <label
                        htmlFor="primary_contact_number"
                        className="form-label"
                      >
                        Contact No.
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="primary_contact_number"
                        name="primary_contact_number"
                        value={formik?.values.primary_contact_number}
                        onChange={handleChange}
                      />
                      {/* <p className="text-danger">{formik?.errors?.primary_contact_number}</p> */}
                    </div>
                    <div class="col-12">
                      <label for="inputAddress2" class="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="2"
                        value={formik?.values.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div class="d-grid mb-3">
                      <button
                        type="submit"
                        class="btn btn-primary rudra-member-submit-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-2 dealership-desktop"></div>
              <div className="col-12 col-md-12 col-lg-5 d-flex flex-column justify-content-center">
                <br className="dealership-desktop" />
                <div className="row my-4 dealership-desktop">
                  <div className="col-12">
                    <h1 className="display-4 fw-bold text-white">
                      Questions!!
                    </h1>
                  </div>
                </div>
                <br className="dealership-desktop" />
                <div className="row dealership-desktop mt-4 mt-md-5">
                  <div className="col-12">
                    <div className="row">
                      <h4 className="fw-bold">Call us to get your Answers</h4>
                      <p className="fs-5">Chirag Shah</p>
                      <p className="fs-5">
                        <a href="tel:+">+91 8238041111</a>
                      </p>
                      <p className="fs-6">Fill The Form To Enquire More</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row dealership-mobile mt-5">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row g-0">
                  <div className="col-12 mb-4">
                    <p>Fill The Form To Enquire More</p>
                    <h4 className="fw-bold">Call us to get your Answers</h4>
                    <p className="fs-5">Chirag Shah</p>
                    <p className="fs-5">
                      <a
                        href="tel:+918238041111"
                        className="text-decoration-none text-dark"
                      >
                        +91 8238041111
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="section-4">
                <div className="row">
                <h1 className="text-white main-heading fw-7 mb-3 p-0 mb-d-text">Questions!!</h1>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="title">Firm Name</label>
                                <input type="text" className="form-control" id="title" name="title" aria-describedby="title"
                                    value={formik?.values.title}
                                    onChange={handleChange} />
                                <p className="text-red">{formik?.errors?.title}</p>
                            </div>
                            <div className="d-flex">
                                <div className="form-group" style={{ marginRight: 5 + 'px' }}>
                                    <label htmlFor="first_name">Name</label>
                                    <input type="text" className="form-control" id="first_name" name="first_name"
                                        value={formik?.values.first_name}
                                        onChange={handleChange} />
                                    <p className="text-red">{formik?.errors?.first_name}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="primary_contact_number">Contact No.</label>
                                    <input type="text" className="form-control" id="primary_contact_number" name="primary_contact_number"
                                        value={formik?.values.primary_contact_number}
                                        onChange={handleChange} />
                                    <p className="text-red">{formik?.errors?.primary_contact_number}</p>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea className="form-control" id="message" rows="3"
                                    value={formik?.values.message}
                                    onChange={handleChange}></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                        <p className="normal-text mt-2 mb-d-text">Fill The Form To Enquire More</p>

                        <div className="info mb-d-text">
                            <p className="bold-text fw-7">Call us to get your Answers</p>
                            <p className="bold-text">Chirag Shah</p>
                            <p className="bold-text">+91 8238041111</p>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 mb-d-none">
                        <h1 className="text-white main-heading fw-7">Questions!!</h1>
                        <div className="info">
                            <p className="bold-text fw-7">Call us to get your Answers</p>
                            <p className="bold-text">Chirag Shah</p>
                            <p className="bold-text">+91 8238041111</p>
                            <p className="normal-text">Fill The Form To Enquire More</p>
                        </div>
                    </div>
                </div>
            </div> */}
      </div>
    </>
  );
};

export default Dealership;
