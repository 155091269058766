import http from "../environment";

const getAll = () => {
    return http.get(`media/get`);
}

const getAchivements = () => {
    return http.get(`media/get?keyword=ach`);
}

const getLaunch = () => {
    return http.get(`media/get?keyword=l`);
}

const getAdvertisement = () => {
    return http.get(`media/get?keyword=ad`);
}

const MediaService = {
    getAll,
    getAchivements,
    getLaunch,
    getAdvertisement
}

export default MediaService;