import React, { useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import GoogleMapReact from "google-map-react";
import InquiryService from "../../api-config/Inquiry";
import { inquiryFormSchema } from "../../validations/InquiryForm";
import backimg from "../../assets/white-bg.png";
import insta from "../../assets/instagram (1).png";
import youtube from "../../assets/youtube.png";
import twitter from "../../assets/twitter.png";
import facebook from "../../assets/facebook (1).png";
import { Helmet } from "react-helmet";
import "../../css/contact.css";
const AnyReactComponent = ({ text }) => <div className="marker"></div>;

const ContactUs = () => {
  const [cordinates, setCordinates] = useState([22.98729, 72.48252]);
  const defaultProps = {
    center: {
      lat: 22.98729,
      lng: 72.48252,
    },
    zoom: 11,
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      first_name: "",
      last_name: "xyz",
      primary_contact_number: "",
      message: "",
      status: "false",
      is_dealer: false,
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      InquiryService.saveDealerInquiry(values)
        .then((res) => {
          if (res.data.success) {
            InquiryService.sendEmail(values).then((res) => {
              console.log(res);
            });
            // InquiryService.sendSMS(values).then((res) => {
            //   console.log(res);
            // });
            resetForm();
            toast.success(res.data.message, { autoClose: 1000 });
          } else {
            toast.error(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((e) => console.log(e));

      const requestData = {
        title: values?.title ?? "",
        first_name: values?.first_name ?? "",
        primary_contact_number: values?.primary_contact_number ?? "",
      };
      InquiryService.thankU(requestData)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, { autoClose: 1000 });
          } else {
            toast.error(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((e) => console.log(e));

      const data = {
        title: values?.title ?? "",
        first_name: values?.first_name ?? "",
        primary_contact_number: values?.primary_contact_number ?? "",
        message: values?.message ?? "",
        status: values.status,
        is_dealer: values.is_dealer,
        inquiry_type: "Normal Inquiry",
      };

      InquiryService.sendInquirySMS(data)
        .then((res) => {
          console.log(res);
        })
        .catch((e) => console.log(e));
    },
    validationSchema: inquiryFormSchema,
    validateOnBlur: true,
  });

  const { handleChange, handleSubmit } = formik;

  return (
    <>
      <Helmet>
        <title>Contact Us | Rudra Global Infra Products, Bhavanagar</title>
      </Helmet>
      <div className="contact-us">
        <div className="backimg h-100 w-100">
          <div className="row section-1 g-0 h-100">
            <div className="col-md-12 col-lg-5 col-xl-5 d-flex align-items-center">
              <form onSubmit={handleSubmit} className="contact-us-form">
                <div className="form-group">
                  <label htmlFor="title">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    aria-describedby="title"
                    value={formik?.values.title}
                    onChange={handleChange}
                  />
                  {/* <p className="text-danger">{formik?.errors?.title}</p> */}
                </div>
                <div className="d-flex justify-content-between">
                  <div
                    className="form-group"
                    style={{ marginRight: 5 + "px", width: "100%" }}
                  >
                    <label htmlFor="first_name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      name="first_name"
                      value={formik?.values.first_name}
                      onChange={handleChange}
                    />
                    {/* <p className="text-danger">{formik?.errors?.first_name}</p> */}
                  </div>
                  <div className="form-group" style={{ width: "100%" }}>
                    <label htmlFor="primary_contact_number">Contact No.</label>
                    <input
                      type="number"
                      className="form-control"
                      id="primary_contact_number"
                      name="primary_contact_number"
                      value={formik?.values.primary_contact_number}
                      onChange={handleChange}
                    />
                    {/* <p className="text-danger">{formik?.errors?.primary_contact_number}</p> */}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="3"
                    value={formik?.values.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
            <div className="col-md-12 col-lg-7 col-xl-7 info-section">
              <div>
                <h2 className="sub-heading">Contact Us</h2>
                {/* <p className="normal-text">Phone: 079-40370112</p> */}
                <p className="normal-text">
                  Mobile: +91 8238041111, +91 8238051111
                </p>
                <p className="normal-text">Email: Info@rudratmx.com</p>
              </div>
              <br />
              <div>
                <h2 className="sub-heading">Rudra Global</h2>
                <address>
                  <p className="normal-text">Plot No. D-60, Rudra House,</p>
                  <p className="normal-text">
                    2nd Floor, Nr.Ram Mantra Mandir,
                  </p>
                  <p className="normal-text"> Kaliabid, Bhavnagar-364002.</p>
                </address>
              </div>
              <br />
              {/* <div className="social-media-icons">
        <h4>Lets get Social</h4>
        <div className="d-flex">
            <img src={insta} alt="" />
            <img src={youtube} alt="" />
            <img src={twitter} alt="" />
            <img src={facebook} alt="" />
        </div>
    </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="contact-us-maps py-5">
        <div className="container">
          <div className="row section-2 gy-5">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card">
                {/* <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBsqJFvJbRA0kP8tCezzr47UwkNWFWi6zM",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  center={cordinates}
                >
                  <AnyReactComponent
                    lat={cordinates[0]}
                    lng={cordinates[1]}
                    text="Rudra TMX"
                  />
                </GoogleMapReact> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59286.757967735844!2d72.1137065141247!3d21.763908263516555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f5081abb84e2f%3A0xf676d64c6e13716c!2sBhavnagar%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1717068400527!5m2!1sen!2sin"
                  style={{ width: "100%", height: "400px" }}
                ></iframe>
                {/* <div className="card-body">
                  <h5 className="card-title">Head Office (Bhavanagar)</h5>
                </div> */}

                <div className="card-body">
                  <h5 className="card-title">Head Office (Bhavanagar)</h5>
                  <p className="card-text m-0">Plot No. D-60, Rudra House,</p>
                  <p className="card-text m-0">
                    2nd Floor, Nr.Ram Mantra Mandir,
                  </p>
                  <p className="card-text m-0">Kaliabid, Bhavnagar-364002.</p>
                </div>
              </div>
              {/* <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyBsqJFvJbRA0kP8tCezzr47UwkNWFWi6zM" }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                center={cordinates}
                            >
                                <AnyReactComponent
                                    lat={cordinates[0]}
                                    lng={cordinates[1]}
                                    text="Rudra TMX" />
                            </GoogleMapReact>
                            <h3 className="sub-heading c-blue">Head Office (Bhavanagar)</h3> */}
            </div>
            {/* <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBsqJFvJbRA0kP8tCezzr47UwkNWFWi6zM",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  center={cordinates}
                >
                  <AnyReactComponent
                    lat={cordinates[0]}
                    lng={cordinates[1]}
                    text="Rudra TMX"
                  />
                </GoogleMapReact>
                <div className="card-body">
                  <h5 className="card-title">Marketing Office (Ahmedabad)</h5>
                  <p className="card-text m-0">804, Signature-1</p>
                  <p className="card-text m-0">Opp Andaz Party Plot,</p>
                  <p className="card-text m-0">S.G. Highway Makarba,</p>
                  <p className="card-text m-0">
                    Ahmedabad - 380051 Gujarat, India
                  </p>
                </div>
              </div>
               <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyBsqJFvJbRA0kP8tCezzr47UwkNWFWi6zM" }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                center={cordinates}
                            >
                                <AnyReactComponent
                                    lat={cordinates[0]}
                                    lng={cordinates[1]}
                                    text="Rudra TMX" />
                            </GoogleMapReact>
                            <h3 className="sub-heading c-blue">Marketing Office (Ahmedabad)</h3>
                            <address>
                                <p className="normal-text m-0">804, Signature-1</p>
                                <p className="normal-text m-0">Opp Andaz Party Plot,</p>
                                <p className="normal-text m-0">S.G. Highway Makarba,</p>
                                <p className="normal-text m-0">Ahmedabad - 380051 Gujarat, India</p>
                            </address>  
            </div> */}
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card">
                {/* <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBsqJFvJbRA0kP8tCezzr47UwkNWFWi6zM",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  center={cordinates}
                >
                  <AnyReactComponent
                    lat={cordinates[0]}
                    lng={cordinates[1]}
                    text="Rudra TMX"
                  />
                </GoogleMapReact> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29653.238565263167!2d71.93847642112125!3d21.71612539706166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f54a15587f03d%3A0x9f5dc5dbb6daae5!2sSihor%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1717068289432!5m2!1sen!2sin"
                  style={{ width: "100%", height: "400px" }}
                ></iframe>

                <div className="card-body">
                  <h5 className="card-title">Plant-Works (Sihor)</h5>
                  <p className="card-text m-0">
                    Survay No144 Paiki 1&2, 145 Paiki 1,
                  </p>
                  <p className="card-text m-0">Village Nesada, Taluka Sihor,</p>
                  <p className="card-text m-0">District Bhavnagar - 364240.</p>
                </div>
              </div>

              {/* <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyBsqJFvJbRA0kP8tCezzr47UwkNWFWi6zM" }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                center={cordinates}
                            >
                                <AnyReactComponent
                                    lat={cordinates[0]}
                                    lng={cordinates[1]}
                                    text="Rudra TMX" />
                            </GoogleMapReact>
                            <h3 className="sub-heading c-blue">Plant-Works (Sihor)</h3>
                            <address>
                                <p className="normal-text m-0">Survay No144 Paiki 1&2,</p>
                                <p className="normal-text m-0">145 Paiki 1, Village Nesada,</p>
                                <p className="normal-text m-0">Taluka Sihor, </p>
                                <p className="normal-text m-0">District Bhavnagar - 364240</p>
                            </address> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
