import http from "../environment";

const getData = (size) => {
    return http.get(`home-slider/get?size=${size}`);
}

const getAllData = () => {
    return http.get(`home-slider/get`);
}

const SliderService = {
    getData,
    getAllData
}

export default SliderService;