import Header from './components/Header';
import Home from './modules/home/Home';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Footer/>
    </div>
  );
}

export default App;
